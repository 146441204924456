.toolbar {
  height: 70px;
}
@media print {
  .toolbar {
    display: none !important;
  }
}

.brandTexts {
  display: flex;
  flex-direction: column;
  transform: translateY(10%);
}
.brandName {
  font-family: brand;
  white-space: nowrap;
  transform: translateY(10%);
}
.brandSlogan {
  transform: translateY(-40%);
  white-space: nowrap;
  font-size: 0.7em;
}
.brandLogo {
  height: 30px;
}

.menu {
  min-height: auto;
  justify-content: center;
  z-index: var(--mui-zIndex-appBar);
}
.menuCard {
  max-width: 350;
  overflow-y: auto;
}

.menuTabRoot:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.menuTabWrapper {
  display: block;
  height: 100%;
}
.menuLink {
  display: flex;
  padding: 0 32px;
  height: 100%;
  width: 100%;

  align-items: center;
  justify-content: center;

  color: inherit;
  text-decoration: none;
}
.link {
  color: inherit;
  text-decoration: none;
}
